<template>
  <div>
    <div class="subHeader base noFlex">
      <div class="module-header">
        <h2>문의사항 관리</h2>
      </div>
      <div class="contWrap flex">
      </div>
      <div class="wtBox">
        <div class="module-filter">
          <div class="module-button">
            <select v-model="inputMode">
              <option value="" disabled>선택</option>
              <option value="title">제목</option>
              <option value="content">내용</option>
            </select>
          </div>
          <div class="search">
            <fieldset>
              <legend>검색</legend>
              <input
                type="search"
                placeholder="검색어를 입력하세요"
                v-model="searchWord"
                @keyup.enter="getQnaList"
              />
              <button type="submit" class="material-icons" @click="getQnaList">
                search
              </button>
            </fieldset>
          </div>
          <button type="button" class="icoTxt" @click="reload">
            <i class="material-icons">replay</i>
            <b>새로고침</b>
          </button>
        </div>
        <div class="module-subfilter">
          <span>답변상태</span>
          <label
            ><input
              type="radio"
              @change="statusChange"
              name="status"
              v-model="status"
              value=""
            />전체</label
          >
          <label
            ><input
              type="radio"
              @change="statusChange"
              name="status"
              v-model="status"
              value="false"
            />답변대기</label
          >
          <label
            ><input
              type="radio"
              @change="statusChange"
              name="status"
              v-model="status"
              value="true"
            />답변완료</label
          >
        </div>
      </div>

      <div class="module-config">
        <div class="wtBox config-list">
          <header class="flexL">
            <h3>문의사항 목록</h3>
            <span class="rubberBand"></span>
          </header>
          <table class="module-table lineRow">
            <tr>
              <th scope="col">번호</th>
              <th scope="col">카테고리</th>
              <th scope="col">제목</th>
              <th scope="col">작성자</th>
              <th scope="col">날짜</th>
              <th scope="col">답변상태</th>
            </tr>
            <tr v-for="(item, index) of qnalist" :key="index">
              <td>{{ item.id }}</td>
              <td>{{ item.category }}</td>
              <td class="leftAlign">
                <router-link :to="`/admin/qnaManagement?id=${item._id}`">{{
                  item.title
                }}</router-link>
              </td>
              <td>{{ item.author.username }}</td>
              <td>{{ moment(item.createdAt).format("YYYY.MM.DD hh:mm") }}</td>
              <td :class="[item.status ? 'orange' : 'primary']">
                {{ item.status ? "답변완료" : "답변대기" }}
              </td>
            </tr>
          </table>

          <div class="pagination" v-if="total > 0">
            <el-pagination
              layout="prev, pager, next"
              :total="total"
              :page-size="10"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import { fetchQnaList } from "@/api/member";
import moment from "moment";
import { mapState } from "vuex";
import { eventBus } from "@/main";
import { setNavbar } from "@/mixins/setNavbar";
import { ko } from "vuejs-datepicker/dist/locale";
export default {
  mixins: [setNavbar],
  data() {
    return {
      isEdit: false,
      ko: ko,
      moment: moment,
      display: true,
      confirmTitle: "",
      message1: "",
      message2: "",
      currentComponent: null,
      displayConfirm: false,
      qnalist: [],
      dateKeyword: "",
      searchWord: "",
      inputMode: "",
      currentPage: 1,
      total: 0,
      typeCode: "",
      startYmd: "",
      endYmd: "",
      periodCode: "",
      status: ""
    };
  },
  mounted() {
    eventBus.$on("closeConfirm", () => {
      this.closeConfirm();
    });
    this.getQnaList();
  },
  computed: {
    ...mapState(["userInfo"])
  },
  methods: {
    statusChange() {
      this.currentPage = 1;
      this.getQnaList();
    },
    viewComponent(view) {
      this.currentComponent = view;
    },
    closeConfirm() {
      this.displayConfirm = false;
    },

    handleCurrentChange(val) {
      this.currentPage = val;
      this.getQnaList();
    },

    reload() {
      this.currentPage = 1;
      this.total = 0;
      this.inputMode = "";
      this.searchWord = "";
      this.status = "";
      this.getQnaList();
    },
    getQnaList() {
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
        inputMode: this.inputMode,
        status: this.status,
      };
      fetchQnaList(params).then(res => {
        if (res.data.status == 200) {
          this.qnalist = res.data.postList;
          this.currentPage = res.data.currentPage;
          this.total = res.data.total;
        } else {
          return alert(res.data.message);
        }
      });
    }
  }
};
</script>
